import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from "../../components/project_page/ProjectPageWrapper"
import TextBlock from "../../components/project_page/TextBlock"
import Mobile3 from "../../components/project_page/Mobile3"
import Web1 from "../../components/project_page/Web1"
import Image1 from "../../components/project_page/Image1"
import ImageGrid from "../../components/project_page/ImageGrid"

import globalStyles from "../global.module.css"
// pro upgrade
const headerBackground = "#f0f3ff"
const navColor = "#222"
const Bounce = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Bounce"
      oneLiner="We pick the place, you bring your face."
      link={null}
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Problem"
          headline={`People use dating apps, but don’t go on dates.`}
          content={`We kept hearing a familiar story from our single friends.
            The process of going from a swipe to a match was straightforward.
            But the process of going from a match to actually meeting was
            littered with frustration: endless swiping, messaging, ghosting,
            back-and-forth scheduling, and of course, flaking.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`Match at 7, date at 8.`}
          content={`Bounce set’s you up on same-night dates.
            Bounce is only live for 15 minutes / day, and if you get
            a match, it's a date! We tell you where to meet based
            on shared location preferences.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          desktopImage={<Img fluid={data.body4.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>

      <div className={`${globalStyles.marginBottomBig}`}>
        <div
          className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}
        >
          <div
            className={`${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}
          >
            <TextBlock
              title="Engineering"
              headline={`Real time matching`}
              content={`Matching happens in real time. When you see someone's profile, they're looking at yours at the same time.
                To support this, we implemented on the fly-matching algorithms
                and a scalable infrastructure to support thousands of simultaneous
                daters.`}
            />
          </div>
          <div
            className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}
          >
            <Image1
              maxWidth={420}
              img={<Img fluid={data.body5.childImageSharp.fluid} />}
            />
          </div>
        </div>
      </div>

      <div className={`${globalStyles.marginBottomHuge}`}>
        <div
          className={`${globalStyles.flex} ${globalStyles.flexReverse} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}
        >
          <div
            className={`${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}
          >
            <TextBlock
              title="Product Design"
              headline={`People not profiles`}
              content={
                <>
                  <p>
                    We want Bounce members to appreciate that everyone on the
                    app is a real person putting themselves out there. Our
                    profile design reflects this mission while still feeling
                    familiar.
                  </p>
                  <p>
                    <ul>
                      <li>
                        Members view eachothers' profiles simultaneously for up
                        to one minute. This is meant to encourage a sense of
                        connectedness and more thoughtful consideration of each
                        person.
                      </li>
                      <li>
                        Each person is introduced with a friendly "Hi I'm _____"
                      </li>
                      <li>
                        People can decide "Meet" or "Next". We use text rather
                        than icons to make these actions feel more explicit and
                        human.
                      </li>
                    </ul>
                  </p>
                </>
              }
            />
          </div>
          <div
            className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}
          >
            <Image1
              maxWidth={420}
              img={<Img fluid={data.body6.childImageSharp.fluid} />}
            />
          </div>
        </div>
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <ImageGrid
          images={[
            <Img fluid={data.body7.childImageSharp.fluid} />,
            <Img fluid={data.body8.childImageSharp.fluid} />,
            <Img fluid={data.body10.childImageSharp.fluid} />,
          ]}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="Microsites"
          headline={`Date spots and dating data.`}
          content={
            <p>
              We launched several microsites to support our users and grow the
              Bounce brand.
            </p>
          }
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Image1
          maxWidth={800}
          img={<Img fluid={data.body11.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Image1
          maxWidth={800}
          img={<Img fluid={data.body12.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Image1
          maxWidth={800}
          img={<Img fluid={data.body13.childImageSharp.fluid} />}
        />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Bounce

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/bounce/Bounce_Header_1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeader: file(
      relativePath: { eq: "projects/bounce/header-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body1: file(relativePath: { eq: "projects/bounce/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body2: file(relativePath: { eq: "projects/bounce/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body3: file(relativePath: { eq: "projects/bounce/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body4: file(relativePath: { eq: "projects/bounce/bounce-web.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body5: file(relativePath: { eq: "projects/bounce/bounce-algo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/bounce/bounce-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/bounce/Photo1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body8: file(relativePath: { eq: "projects/bounce/Photo2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body9: file(relativePath: { eq: "projects/bounce/Photo3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body10: file(relativePath: { eq: "projects/bounce/Photo4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body11: file(relativePath: { eq: "projects/bounce/Survey.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body12: file(relativePath: { eq: "projects/bounce/Hotline.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body13: file(relativePath: { eq: "projects/bounce/Fifty.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
